import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Styled from 'styled-components'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Banner = Styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-bottom: 20px;
`
export default class Page extends Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO title="Home" />
        <Banner>
          <Img fluid={data.wordpressPage.featured_media.localFile.childImageSharp.fluid} />
        </Banner>
        <div
          dangerouslySetInnerHTML={{
            __html: data.wordpressPage.content,
          }}
        />
      </Layout>
    )
  }
}

export const query = graphql`
  query HomePageQuery {
    wordpressPage(slug: { eq: "home" }) {
      title
      content
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
